<template>
  <div
    class="bg-nav_color flex  text-sm  flex-col rounded-lg bg-opacity-5 w-48 my-3 xs:p-2 xs:ltr:mr-4 xs:rtl:ml-4 lg:ltr:mr-7 lg:rtl:ml-7"
  >
    <div class="flex justify-between">
      <div v-if="address.text_address" class=" font-light flex">
        {{ t('name_location') }}: {{ address.text_address }}
      </div>
      <button
        class="max bg-nav text-red-500"
        :aria-label="t('delete_item', { name: t('item') })"
        @click="emit('delete', address.id)"
      >
        <v-icon icon="mdi mdi-trash-can-outline" class="text-sm" :size="20" />
      </button>
    </div>
    <div v-if="address.city?.name" class="font-light flex">
      {{ t('city') }}: {{ address.city?.name }}
    </div>
    <div class="font-light flex flex-wrap">
      <div v-if="address.street">{{ t('street') }}:{{ address.street }} </div>
      <div class="w-2"></div>
      <div v-if="address.building"> {{ t('building') }}:{{ address.building }} </div>
    </div>
    <div class="font-light flex flex-wrap">
      <div v-if="address.apartment">{{ t('apartment') }}:{{ address.apartment }} </div>
      <div class="w-2"></div>
      <div v-if="address.floor"> {{ t('floor') }}:{{ address.floor }} </div>
    </div>
    <div v-if="address.details" class="font-light"> {{ t('details') }}:{{ address.details }} </div>
    <MyAccountPlacesGoogleMap
      v-if="address.LocationAddress"
      :readonly="true"
      high="150"
      :location="address.LocationAddress"
      class="mx-auto w-full mt-2"
    />
    <EStoreResponseError
      class="mb-2 mt-auto"
      :api-key="`deleteAgentPlace-${address.id}`"
    />
  </div>
</template>

<script setup lang="ts">
withDefaults(
  defineProps<{
    address: AgentPlace
  }>(),
  {}
)

const { t } = useI18n()

const emit = defineEmits(['delete'])
</script>

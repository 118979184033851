<template>
  <div class="bg-bg_alt_color p-2 rounded shadow">
    <EStoreDialouge width="auto">
      <template #activator="{ props: propsTemp }">
        <div class="flex justify-center mt-6 mb-4">
          <EStoreSubmitButton
            v-bind="propsTemp"
            icon="mdi mdi-map-marker-circle"
            color="bg-button_color"
            class="max-w-40 text-sm bg-opacity-70"
            :title="t('create_address')"
            :loading="false"
            @submit="null"
          />
        </div>
      </template>
      <template #content="{ close }">
        <MyAccountPlacesCreateForm @created="close" />
      </template>
    </EStoreDialouge>
    <div v-if="places?.length" class="min-h-[50px]">
      <RikazGrid
        name="material"
        :items="places"
        margin="sm:ltr:-ml-3.5 sm:rtl:-mr-3.5"
      >
        <template #card="{ forKey, data }">
          <MyAccountPlacesCard
            :key="`place-grid-item-${forKey}`"
            :address="( data as AgentPlace )"
            @delete="deletePlaceLocal"
          />
        </template>
      </RikazGrid>
    </div>
  </div>
</template>

<script setup lang="ts">
import { usePlacesStore } from '~/store/places'
import type { AgentPlace } from '~~/composables/useMenuModel'
const { t } = useI18n()
const { fetchAgentPlace, deleteAgentPlace } = usePlacesStore()
await fetchAgentPlace()
async function deletePlaceLocal(addressId: number) {
  await deleteAgentPlace(addressId)
}

const places = computed(() => {
  return usePlacesStore().places
})
</script>
